import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FetchData from '../../../Helper/HttpRequestHelper';
import { Spinner } from '../../components/misc';
import showPassword from '../../../../assets/img/eye.svg';
import hidePassword from '../../../../assets/img/eye-slash.svg';
import ThemeContext from '../../../context/ThemeContext';

const ManagePassword = () => {

    const [passwordInfo, setPasswordInfo] = useState({ old_password: '', new_password: '' });
    const [toggleOldPassword, setToggleOldPassword] = useState(false);
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const [isFrmReady, setIsFrmReady] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [notification, setNotification] = useState({});
    const [frmErrors, setFrmErrors] = useState({});
    const { setAlert } = useContext(ThemeContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (passwordInfo?.password !== '' && passwordInfo?.new_password !== '') {
            setIsFrmReady(true);
        }
        else {
            setIsFrmReady(false);
        }
    }, [passwordInfo]);

    const __onChange = (e) => {
        const newPassword = { ...passwordInfo };
        newPassword[e.target.name] = e.target.value;
        setPasswordInfo(newPassword);
    }

    const __onSubmit = (e) => {
        e.preventDefault();
        setLoadingSubmit(true);
        FetchData('member/update-password', passwordInfo).then(res => {
            console.log(res.status_code);
            if (res.status_code === 200) {
                // setNotification({
                //     show: true,
                //     type: 'success',
                //     text: 'Update password berhasil, silahkan login dengan password baru anda.'
                // });
                setAlert({
                    show: true,
                    title: 'Berhasil',
                    message: 'password berhasil diperbarui, silahkan login dengan password baru anda.'
                })
                setPasswordInfo({});
                setFrmErrors({});

            }
            else if (res.status_code === 422) {
                setNotification({
                    show: true,
                    type: 'danger',
                    text: res.message
                });
                setFrmErrors(res.errors);
            }
            else {
                setNotification({
                    show: true,
                    type: 'danger',
                    text: 'Terjadi kesalahan, mohon coba beberapa saat kembali.'
                });
                setFrmErrors({});
            }
        })
            .catch(err => {
                if (err.message === 'Unauthenticated.') {
                    navigate('/login?path=' + encodeURIComponent(window.location.pathname) + '&error=auth-expired');
                }
            })
            .finally(() => {
                setLoadingSubmit(false)
            });
    }

    return (
        <div>
            <h2 className='mb-4'>Ubah Password</h2>
            {
                notification?.show &&
                <Alert variant={notification?.type} className='mb-4'>
                    {notification.text}
                </Alert>
            }
            <Form className='form-user' onSubmit={__onSubmit}>
                <Form.Group className='mb-3'>
                    <Form.Label>Password saat ini</Form.Label>
                    <InputGroup className={`mb-3 ${frmErrors?.password ? 'has-validation' : ''}`}>
                        <Form.Control
                            type={`${toggleOldPassword ? 'text' : 'password'}`}
                            name='password'
                            value={passwordInfo?.password || ''}
                            className={`${frmErrors?.password ? 'is-invalid' : ''}`}
                            onChange={__onChange}
                            required
                        />
                        <Button
                            variant='outline-secondary'
                            onClick={() => {
                                setToggleOldPassword(!toggleOldPassword);
                            }}
                        >
                            {toggleOldPassword ?
                                <img src={showPassword} alt="show password" />
                                :
                                <img src={hidePassword} alt="hide password" />
                            }
                        </Button>
                        {frmErrors?.password &&
                            <div className="invalid-feedback">{frmErrors?.password}</div>}
                    </InputGroup>
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>Password baru</Form.Label>
                    <InputGroup className={`mb-3 ${frmErrors?.new_password ? 'has-validation' : ''}`}>
                        <Form.Control
                            type={`${toggleNewPassword ? 'text' : 'password'}`}
                            name='new_password'
                            value={passwordInfo?.new_password || ''}
                            className={`${frmErrors?.new_password ? 'is-invalid' : ''}`}
                            onChange={__onChange}
                            required
                        />
                        <Button
                            variant='outline-secondary'
                            onClick={() => {
                                setToggleNewPassword(!toggleNewPassword);
                            }}
                        >
                            {toggleNewPassword ?
                                <img src={showPassword} alt="show password" />
                                :
                                <img src={hidePassword} alt="hide password" />
                            }
                        </Button>
                        {frmErrors?.new_password &&
                            <div className="invalid-feedback">{frmErrors?.new_password}</div>}
                    </InputGroup>
                </Form.Group>
                <div className="text-end mt-4 mb-5">
                    <Button variant={!isFrmReady ? 'secondary' : 'primary'} disabled={!isFrmReady || loadingSubmit} style={{ minWidth: 170 }} type="submit">
                        {loadingSubmit ?
                            <Spinner />
                            :
                            `Ubah Password`
                        }
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default ManagePassword;