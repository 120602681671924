/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import envelopeImg from '../../../../assets/img/envelop.svg';
import FetchData from "../../../Helper/HttpRequestHelper";
import { Spinner } from "../../components/misc";

const timeOut = 60;

const VerifyEmailPage = () => {

    const [seconds, setSeconds] = useState(timeOut);
    const [btnResendEmail, setBtnResendEmail] = useState(true);
    const [resendEmailLoading, setResendEmailLoading] = useState(false);
    const [checkVerifiedLoading, setCheckVerifiedLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [errorTxt, setErrorTxt] = useState('');
    const [successTxt, setSuccessTxt] = useState('');
    const [resendEmailSuccess, setResendEmailSuccess] = useState('');
    const [resendEmailError, setResendEmailError] = useState(false);
    const { id_enc } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => { setSeconds(seconds - 1) }, 1000);
        }
        else {
            if (btnResendEmail) {
                setBtnResendEmail(false);
            }
        }
    }, [seconds]);

    useEffect(() => {
        setTimeout(() => {
            _checkIsVerified();
        }, 1000)
    }, []);

    const _checkIsVerified = () => {
        setCheckVerifiedLoading(true);
        FetchData('verify-email/check', { id_enc: id_enc }).then(response => {
            if (response.status_code === 500) {
                navigate('/');
            }
            if (response.status_code === 200 && response.data?.is_verifed === true) {
                navigate('/');
            }
            setEmail(response.data?.email);
            setCheckVerifiedLoading(false);
        }).catch(err => {
            navigate('/');
            console.log(err);
        })
    }

    const resendEmail = () => {
        setResendEmailLoading(true);
        setTimeout(() => {
            FetchData('verify-email/resend-link', { email: email }).then(response => {
                setSeconds(timeOut);
                if (response.message === 'Too Many Attempts.') {
                    setErrorTxt('Email telah kami kirim ke email anda. jika anda ingin mengirim ulang mohon tunggu selama 1 menit.');
                    setResendEmailError(true);
                }
                if (response?.status_code === 200) {
                    setSuccessTxt('Email verifikasi terkirim, silahkan periksan email anda.');
                    setResendEmailSuccess(true);
                }
            }).catch(err => {
                resendEmailError(true);
            }).finally(() => {
                setBtnResendEmail(true);
                setResendEmailLoading(false);
            });
        }, 500);
    }

    return (
        <React.Fragment>
            <img src={envelopeImg} className="img-envelop d-block m-auto" alt="" />
            {checkVerifiedLoading ?
                <>
                    <div className="mt-4">
                        <Skeleton count={3} />
                    </div>
                    <Skeleton height={40} className="mt-3" />
                </>
                :
                <>
                    <p className="text-center mt-4 my-4 text-grey lh-base">
                        Link verifikasi telah dikirim ke <span className="text-dark">{email}</span>,<br />
                        segera aktifkan akun anda sebelum 1x24 jam.
                    </p>
                    {resendEmailError &&
                        <div className="alert alert-danger">
                            { errorTxt }
                        </div>
                    }
                    {
                        resendEmailSuccess &&
                        <div className="alert alert-success">
                            { successTxt }
                        </div>
                    }
                    <button
                        className={`btn w-100 ${btnResendEmail ? 'btn-disabled' : 'btn-primary'}`}
                        disabled={btnResendEmail}
                        onClick={resendEmail}
                    >
                        {btnResendEmail ?
                            `Kirim Ulang dalam ${seconds}`
                            :
                            resendEmailLoading ?
                                <Spinner />
                                :
                                `Kirim Ulang Email`
                        }
                    </button>
                </>
            }
        </React.Fragment>
    )
}

export default VerifyEmailPage;