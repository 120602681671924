import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

function UserContextProvider(props) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('KTDTTOK');
    const storedUser = localStorage.getItem('KTDTUSR');
    if (storedToken && storedUser) {
      setToken(storedToken);
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = (userData) => {
    setToken(userData.token);
    setUser(userData.user);
    localStorage.setItem('token', userData.token);
    localStorage.setItem('user', JSON.stringify(userData.user));
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  const updateUser = () => {
    const storedUser = localStorage.getItem('KTDTUSR');
    setUser(JSON.parse(storedUser));
  }

  // const removeStorage = () => {
  //   localStorage.removeItem('KTDTTOK');
  //   localStorage.removeItem('KTDTUSR')
  // }

  // const createStorage = (token, user) => {
  //   localStorage.setItem('KTDTTOK', token);
  //   localStorage.setItem('KTDTTUSR', user);
  // }
  
  return (
    <UserContext.Provider value={{ user, updateUser, token, login, logout }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
