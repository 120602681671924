import React, { useState } from 'react';
import ThemeContext from './ThemeContext';
import SpinnerOverlay from '../view/components/SpinnerOverlay';
import AlertModal from '../view/components/AlertModal';
import ConfirmModal from '../view/components/ConfirmModal';

const ThemeProvider = ({ children }) => {
  const [isSpinnerShow, setIsSpinnerShow] = useState(false);
  const [alert, setAlert] = useState({ show: false, title: '', body: '' });
  const [confirm, setConfirm] = useState({ show: false, title: '', message: '', callback: null });
  const [drawer, setDrawer] = useState(false);
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);

  const toggleSpinner = (isShow = false) => {
    setIsSpinnerShow(isShow);
  };

  const toggleDrawer = () => {
    setDrawer(!drawer);
  }

  function showConfirm(message, callback, title = 'Konfirmasi') {
    setConfirm({ show: true, message, title, callback });
  }

  function hideConfirm() {
    setConfirm({ show: false, message: '', title: '', callback: null });
  }

  function hideLoginModal() {
    setIsShowLoginModal(false);
  }

  function showLoginModal() {
    setIsShowLoginModal(true);
  }


  return (
    <ThemeContext.Provider value={{
      toggleSpinner,
      alert,
      setAlert,
      confirm,
      showConfirm,
      hideConfirm,
      drawer,
      toggleDrawer,
      showLoginModal,
      hideLoginModal,
      isShowLoginModal
    }}>
      {children}
      {isSpinnerShow && <SpinnerOverlay />}
      {alert?.show && <AlertModal />}
      {confirm?.show && <ConfirmModal />}
    </ThemeContext.Provider>
  );
};


export default ThemeProvider;
