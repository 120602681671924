/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FetchData from "../../../Helper/HttpRequestHelper";
import { toHumanDateTime, toRupiah } from "../../../Helper/StringHelper";
import { Button, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const TransactionPage = (props) => {
  const query = useQuery();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const [subType, setActiveSubType] = useState(query.get("subtype"));
  const [type, setActiveType] = useState(query.get("type"));

  useEffect(() => {
    setActiveSubType(query.get("subtype"));
  }, [type]);

  const menus = [
    {
      type: "subscription",
      text: "Bulanan / Tahunan",
    },
    {
      type: "retail",
      text: "Per Artikel (Retail)",
    },
  ];

  const subTypes = [
    {
      type: "all",
      text: "ALL",
    },
    {
      type: "pending",
      text: "UNPAID",
    },
    {
      type: "paid",
      text: "PAID",
    },
    {
      type: "canceled",
      text: "CANCELED",
    },
  ];

  const handleChangeSubType = (menu) => {
    setActiveSubType(menu.type);
    params.set("subtype", menu.type); // Update or set the query param
    navigate(`?${params.toString()}`);
  };

  return (
    <>
      <h2 className="mb-4">Transaksi</h2>
      <nav className="nav nav-tabs mb-4">
        {menus.map((menu, index) => (
          <li className="nav-item">
            <Link
              to={`/m/transaction?type=${menu.type}&subtype=all`}
              onClick={() => {
                setActiveType(menu.type);
              }}
              className={`nav-link ${
                query.get("type") === menu.type ? "active" : ""
              }`}
            >
              {menu.text}
            </Link>
          </li>
        ))}
      </nav>
      <div className="mb-3">
        {subTypes.map((menu, index) => (
          <Button
            key={index}
            onClick={() => handleChangeSubType(menu)}
            variant={`btn ${
              menu.type === subType ? "btn-primary" : "btn-outline-primary"
            } me-2 btn-md`}
          >
            {menu.text}
          </Button>
        ))}
      </div>
      {query.get("type") === "subscription" ? (
        <SubscriptionView type={type} subType={subType} />
      ) : (
        <RetailView type={type} subType={subType} />
      )}
    </>
  );
};

const __fetchDataTransaction = async (type, subType = "all") => {
  const url =
    type === "subscription"
      ? "member/transactions/subscription"
      : "member/transactions/retail";
  const result = await FetchData(url, {
    payment_status: subType,
  });
  return result;
};

const TransactionItem = ({ type, transaction, onShowDetail }) => {
  return (
    <div className="card mb-4 card-transaction">
      <div className="card-body">
        <div className="card-title d-flex justify-content-between align-items-center">
          <div className="invoice-label">No. {transaction.invoice_number}</div>
          <div>
            <div
              className={`label-status ${
                transaction.payment_status === "PENDING"
                  ? "bg-warning"
                  : transaction.payment_status === `EXPIRED` ||
                    transaction.payment_status === "CANCELED"
                  ? "bg-danger"
                  : "bg-success text-white"
              } rounded`}
            >
              {transaction.payment_status}
            </div>
          </div>
        </div>
        <div className="card-text">
          <dl className="d-flex">
            <dt className="col-4 col-md-3">Deskripsi</dt>
            <dd>{transaction.description}</dd>
          </dl>
          {transaction?.article && (
            <dl className="d-flex">
              <dt className="col-4 col-md-3">Data Stories</dt>
              <dd>
                {" "}
                <Link to={transaction.article.url} target="_blank">
                  {transaction.article.nama}
                </Link>
              </dd>
            </dl>
          )}
          <dl className="d-flex">
            <dt className="col-4 col-md-3">Durasi</dt>
            <dd>
              {toHumanDateTime(transaction.subscription_start, true)} -{" "}
              {toHumanDateTime(transaction.subscription_end, true)}
            </dd>
          </dl>
          {transaction.payment_status === "PENDING" && (
            <dl className="d-flex">
              <dt className="col-4 col-md-3">Bayar Sebelum</dt>
              <dd>{toHumanDateTime(transaction.invoice_expire_at)}</dd>
            </dl>
          )}
          <dl className="d-flex">
            <dt className="col-4 col-md-3">Jumlah Tagihan</dt>
            <dd>{toRupiah(transaction.total_price)}</dd>
          </dl>
        </div>
        <div className="card-footer d-flex justify-content-between align-items-center">
          <Link
            to={`${process.env.REACT_APP_API_URL}download/invoice/${
              type === "subscription" ? "subscription" : "retail"
            }/${transaction.invoice_number}`}
          >
            Download Invoice
          </Link>
          <div>
            <button
              className="btn btn-primary btn-sm"
              to={"/"}
              onClick={() => onShowDetail(transaction)}
            >
              Detail
            </button>
            {transaction.payment_status === "PENDING" && (
              <Link
                className="btn btn-success btn-sm ms-3"
                to={transaction.invoice_url}
              >
                Bayar Sekarang
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DetailModal = ({ show, handleClose, transaction }) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
      <Modal.Header>
        <Modal.Title className="text-primary">
          {transaction.invoice_number}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="card card-transaction">
          <div className="card-body">
            <div className="card-text">
              <dl className="d-flex">
                <dt className="col-5 col-md-3">Deskripsi</dt>
                <dd>{transaction.description}</dd>
              </dl>
              {transaction?.article && (
                <dl className="d-flex">
                  <dt className="col-5 col-md-3">Data</dt>
                  <dd>
                    {" "}
                    <Link to={transaction.article.url} target="_blank">
                      {transaction.article.nama}
                    </Link>
                  </dd>
                </dl>
              )}
              <dl className="d-flex">
                <dt className="col-5 col-md-3">Durasi</dt>
                <dd>
                  {toHumanDateTime(transaction.subscription_start, true)} -{" "}
                  {toHumanDateTime(transaction.subscription_end, true)}
                </dd>
              </dl>
              {transaction.payment_status === "PENDING" && (
                <dl className="d-flex">
                  <dt className="col-5 col-md-3">Bayar Sebelum</dt>
                  <dd className="text-danger">
                    {toHumanDateTime(transaction.invoice_expire_at)}
                  </dd>
                </dl>
              )}
              <dl className="d-flex">
                <dt className="col-5 col-md-3">Jumlah Tagihan</dt>
                <dd>{toRupiah(transaction.total_price)}</dd>
              </dl>
              <dl className="d-flex">
                <dt className="col-5 col-md-3 d-block d-md-none">
                  Status <br /> Pembayaran
                </dt>
                <dt className="col-5 col-md-3 d-none d-md-block">
                  Status Pembayaran
                </dt>
                <dd>
                  {" "}
                  <span
                    className={`label-status ${
                      transaction.payment_status === "PENDING"
                        ? "bg-warning text-white"
                        : transaction.payment_status === `EXPIRED`
                        ? "bg-danger"
                        : "bg-success text-white"
                    } rounded`}
                  >
                    {transaction.payment_status}
                  </span>
                </dd>
              </dl>
              {transaction.payment_status === "PAID" && (
                <>
                  <dl className="d-flex">
                    <dt className="col-5 col-md-3">Jumlah Dibayar</dt>
                    <dd>{toRupiah(transaction.paid_amount)}</dd>
                  </dl>
                  <dl className="d-flex">
                    <dt className="col-5 col-md-3">Tanggal Pembayaran</dt>
                    <dd>{toHumanDateTime(transaction.paid_at)}</dd>
                  </dl>
                  <dl className="d-flex">
                    <dt className="col-5 col-md-3">Metode Pembayaran</dt>
                    <dd>
                      {transaction.payment_method} (
                      {transaction.payment_channel})
                    </dd>
                  </dl>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="btn-sm">
          Tutup
        </Button>
        {transaction.payment_status === "PENDING" && (
          <Link to={transaction.invoice_url} className="btn btn-success btn-sm">
            Bayar Sekarang
          </Link>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const SubscriptionView = (props) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();
  const [showDetail, setShowDetail] = useState(false);
  const [itemDetail, setItemDetail] = useState({});
  const { subType } = props;

  useEffect(() => {
    __getDataTransaction();
  }, []);

  useEffect(() => {
    __getDataTransaction(subType);
  }, [subType]);

  const __getDataTransaction = async (subType = "all") => {
    setLoading(true);
    const res = await __fetchDataTransaction("subscription", subType);
    if (res.status_code === 200) {
      setTransactions(res.data.data);
    } else if (res.message === "Unauthenticated.") {
      navigate("/login?path=" + encodeURIComponent(window.location.pathname));
    } else {
      console.log("Error when fetch api get data transaction");
    }
    setLoading(false);
  };

  const onDetailShow = (transaction) => {
    setShowDetail(true);
    setItemDetail(transaction);
  };

  const onDetailClose = () => {
    setShowDetail(false);
    setItemDetail({});
  };

  return (
    <>
      {loading === false && transactions.length === 0 ? (
        <div className="alert alert-info">Transaksi tidak ditemukan</div>
      ) : (
        <>
          {loading === true ? (
            <LoadingSkeleton />
          ) : (
            transactions.map((transaction, index) => (
              <TransactionItem
                key={index}
                transaction={transaction}
                type={props.type}
                onShowDetail={onDetailShow}
              />
            ))
          )}
        </>
      )}
      <DetailModal
        show={showDetail}
        handleClose={onDetailClose}
        transaction={itemDetail}
      />
    </>
  );
};

const LoadingSkeleton = () => (
  <div className="card mb-4 card-transaction skeleton">
    <div className="card-body">
      <div
        className="card-title d-flex justify-content-between align-items-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <div className="invoice-label">
          <Skeleton width={150} />
        </div>
        <div>
          <Skeleton width={100} />
        </div>
      </div>
    </div>
    <div className="card-text">
      <dl className="d-flex">
        <dt className="col-4 col-md-3">
          <Skeleton width={150} />
        </dt>
        <dd>
          <Skeleton width={200} />
        </dd>
      </dl>
      <dl className="d-flex">
        <dt className="col-4 col-md-3">
          <Skeleton width={150} />
        </dt>
        <dd>
          {" "}
          <Skeleton width={400} />
        </dd>
      </dl>
      <dl className="d-flex">
        <dt className="col-4 col-md-3">
          <Skeleton width={150} />
        </dt>
        <dd>
          <Skeleton width={300} />
        </dd>
      </dl>
    </div>
    <div className="card-footer d-flex justify-content-end align-items-center gap-2">
      <Skeleton width={70} height={25} />
      <Skeleton width={70} height={25} />
    </div>
  </div>
);

// const Pagination = () => {
//   return (
//     <nav aria-label="navigation">
//       <ul class="pagination">
//         <li class="page-item">
//           <Link class="page-link" to="/">
//             Previous
//           </Link>
//         </li>
//         <li class="page-item">
//           <Link class="page-link" to="/">
//             Next
//           </Link>
//         </li>
//       </ul>
//     </nav>
//   );
// };

const RetailView = (props) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();
  const [showDetail, setShowDetail] = useState(false);
  const [itemDetail, setItemDetail] = useState({});
  const { subType } = props;

  useEffect(() => {
    __getDataTransaction();
  }, []);

  useEffect(() => {
    setLoading(true);
    __getDataTransaction(subType);
  }, [subType]);

  const onDetailShow = (transaction) => {
    setShowDetail(true);
    setItemDetail(transaction);
  };

  const onDetailClose = () => {
    setShowDetail(false);
    setItemDetail({});
  };

  const __getDataTransaction = async (subType) => {
    setLoading(true);
    const res = await __fetchDataTransaction("retail", subType);
    if (res.status_code === 200) {
      setTransactions(res.data.data);
    } else if (res.message === "Unauthenticated.") {
      navigate("/login?path=" + encodeURIComponent(window.location.pathname));
    } else {
      console.log("Error when fetch api get data transaction");
    }
    setLoading(false);
  };

  return (
    <div>
      {/* <div className="d-flex justify-content-end">
        <Pagination />
      </div> */}
      {loading === false && transactions.length === 0 ? (
        <div className="alert alert-info">Transaksi tidak ditemukan</div>
      ) : (
        <>
          {loading === true ? (
            <LoadingSkeleton />
          ) : (
            transactions.map((transaction, index) => (
              <TransactionItem
                key={index}
                transaction={transaction}
                type={props.type}
                onShowDetail={onDetailShow}
              />
            ))
          )}
        </>
      )}
      <DetailModal
        show={showDetail}
        handleClose={onDetailClose}
        transaction={itemDetail}
      />
      {/* <div className="d-flex justify-content-end">
        <Pagination />
      </div> */}
    </div>
  );
};
export default TransactionPage;
