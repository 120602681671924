import React from "react";
import { Outlet } from "react-router-dom";

const CleanLayout = () => {
    return (
        <div className="main">
            <div className="container">
                <div className="row justify-content-center align-items-center min-vh-100">
                    <div className="col-12 col-lg-5">
                        <div className="card border-0">
                            <div className="card-body">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CleanLayout;