/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FetchData from "../../../Helper/HttpRequestHelper";
import { BsInput } from "../../components/bootstrap";
import { Spinner } from "../../components/misc";
import iconGoogle from "../../../../assets/img/icon-google.png";
import showPassword from '../../../../assets/img/eye.svg';
import hidePassword from '../../../../assets/img/eye-slash.svg';

function RegisterPage() {

  const [registInfo, setRegisInfo] = useState({ name: '', phone: '', email: '', password: '' });
  const [isFormReady, setIsFormReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState({});
  const [togglePasswordType, setTogglePasswordType] = useState(true);
  const navigate = useNavigate();

  const _onInputChange = (event) => {
    let newRegistInfo = { ...registInfo };
    newRegistInfo[event.target.name] = event.target.value;
    setRegisInfo({
      ...newRegistInfo,
    });
  }

  const _onSubmitHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      _submitRegistration();
    }, 10);
  }

  const _submitRegistration = () => {
    FetchData('register', registInfo).then(response => {
      setIsLoading(false);
      if (response.status_code === 200 && response.success === true) {
        navigate(`/verify-your-email/${response.data?.id_enc}`);
      }
      setLoginError(response);
    }).catch(error => {
      console.log(error);
    });
  }

  const _checkEmptyForm = () => {
    for (const property in registInfo) {
      if (!registInfo[property] && property !== 'phone') {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    let isFormReady = _checkEmptyForm();
    if (isFormReady) {
      setIsFormReady(true);
    }
    else {
      setIsFormReady(false);
    }
  }, [registInfo]);

  const loginGoogle = () => {
    window.location.href = process.env.REACT_APP_API_URL + 'auth/google';
  }

  return (
    <React.Fragment>
      <h2 className="card-title text-center">Registrasi</h2>
      <p className="card-text text-center">Daftar untuk mengakses data dan informasi terkini. Satu akun untuk semua platform.</p>
      <form method="post" onSubmit={_onSubmitHandler} className="d-grid gap-3 my-4">
        <BsInput
          placeholder="Nama Lengkap*"
          type="text"
          name="name"
          className="form-controller"
          onChange={_onInputChange}
          errors={loginError?.errors?.name}
        />
        <BsInput
          placeholder="No Telepon (Opsional)"
          type="number"
          name="phone"
          className="form-controller"
          onChange={_onInputChange}
          errors={loginError?.errors?.phone}
        />
        <BsInput
          placeholder="Email*"
          type="email"
          name="email"
          className="form-controller"
          onChange={_onInputChange}
          errors={loginError?.errors?.email}
        />
        <div className="form-group">
          <div className={`input-group ${!!loginError?.errors?.password ? 'has-validation' : ''}`}>
            <input
              placeholder="Password*"
              type={togglePasswordType ? 'password' : 'text'}
              name="password"
              className={`form-control ${!!loginError?.errors?.password ? 'is-invalid' : ''}`}
              onChange={_onInputChange} />
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="toggle-password"
              onClick={() => {
                setTogglePasswordType(!togglePasswordType);
              }}>
              <img src={togglePasswordType ? hidePassword : showPassword} alt="profile" />
              {/* {togglePasswordType ? 'Show' : 'Hide'} */}
            </button>
            {loginError?.errors?.password &&
              <div className="invalid-feedback">
                {loginError?.errors?.password[0]}
              </div>
            }
          </div>
        </div>
        <div className="d-grid gap-3">
          <button type="submit" className={`btn ${!isFormReady ? 'btn-secondary' : 'btn-primary'}`} disabled={!isFormReady} onClick={_onSubmitHandler}>
            {
              !isLoading ?
                'Registrasi'
                :
                <Spinner />
            }
          </button>
          <div className="text-center text-muted">Atau Gunakan</div>
          <button type="button" className="btn btn btn-outline-secondary muted" onClick={loginGoogle}>
            <img src={iconGoogle} alt="" className="icon-google" />&nbsp;
            Masuk dengan Google
          </button>
          <div className="text-center">
            <span className="text-muted">Sudah punya akun?</span> <Link to="/login">Masuk!</Link>
          </div>
          <div className="text-center">
            <span className="text-muted">
              Dengan mendaftar/masuk ke Katadata, kamu menyetujui bahwa <Link target="_blank" to="https://katadata.co.id/kebijakan-privasi">Ketentuan dan Kebijakan privasi</Link> Katadata berlaku untukmu
            </span>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}

export default RegisterPage;
