/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import envelopeImg from '../../../../assets/img/envelop.svg';
import FetchData from "../../../Helper/HttpRequestHelper";
// import { Spinner } from "../../components/misc";

// const timeOut = 60;

const SubmitVerifyEmail = () => {

    const navigate = useNavigate();
    const [verifiedLoading, setVerifiedLoading] = useState(false);
    const [notifInfo, setNotifInfo] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        _doVerified();
    }, []);

    const _doVerified = () => {
        setVerifiedLoading(true);
        FetchData('verify-email/submit', {
            id: id
        }).then((res) => {
            if (res?.status_code === 200) {
                setNotifInfo({
                    'text': 'Email Berhasil diverifikasi.',
                    'type': 'success'
                })
            }
            else {
                setNotifInfo({
                    'text': res.message,
                    'type': 'danger'
                })
            }
            console.log(res?.message);
            if(res?.message === 'Too Many Attempts.') {
                setNotifInfo({
                    'text': 'Mohon coba beberapa saat lagi.',
                    'type': 'danger'
                })
            }
            setVerifiedLoading(false);
        }).catch((err) => {
            console.log(err)
        });
    }

    return (
        <React.Fragment>
            <img src={envelopeImg} className="img-envelop d-block m-auto" alt="" />
            {verifiedLoading ?
                <>
                    <div className="mt-4">
                        <Skeleton count={2} />
                    </div>
                    <Skeleton height={40} className="mt-3" />
                </>
                :
                <>
                    {
                        <Alert className="mt-4" key={notifInfo?.type} variant={notifInfo?.type}>
                            {notifInfo?.text}
                        </Alert>
                    }
                    {/* <p className="text-center mt-4 my-4 text-grey lh-base">
                        Email Berhasil diverifikasi.
                    </p> */}
                    <button
                        className={`btn w-100 btn-primary`}
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        Login
                    </button>
                </>
            }
        </React.Fragment>
    )
}

export default SubmitVerifyEmail;