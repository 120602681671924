import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import ThemeContext from "../../context/ThemeContext";
import IconSuccess from "../../../assets/img/icon-alert-success.svg";
import { useNavigate } from "react-router-dom";

const AlertModal = () => {
    const { alert, setAlert } = useContext(ThemeContext);
    const navigate = useNavigate();

    const handleClose = () => setAlert({ ...alert, show: false });
    const onHide = () => {
        setAlert({...alert, show: false});
        // navigate('/');
    }

    const onLogin = () => {
        setAlert({...alert, loginBtn: false, show: false});
        navigate('/');
    }
    return (
        <Modal
            show={alert.show}
            onHide={handleClose}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="d-grid gap-3 text-center my-3">
                <img src={IconSuccess} alt="" className="mx-auto mb-2" />
                <h2>{alert.title}</h2>
                <div className="mb-2 text-grey">
                    {alert?.message}
                </div>
                <Button
                    type="primary"
                    className="fw-bold"
                    onClick={ alert.loginBtn ? onLogin : onHide}>{ alert.loginBtn ? 'Login' : 'Tutup' }</Button>
            </Modal.Body>
        </Modal>
    );
}

export default AlertModal;