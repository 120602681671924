const BsInput = (props) => {
    return (
        <div className="form-group ">
            { props?.label &&
                <label>{props.label}</label>
            }
            <input
                placeholder={props.placeholder}
                type={props.type}
                name={props.name}
                className={`form-control ${props.className} ${!!props?.errors ? 'is-invalid' : ''}`}
                onChange={props.onChange}
            />
            {!!props?.errors &&
                <div className="invalid-feedback">
                    {props?.errors}
                </div>
            }
        </div>
    )
}

export {
    BsInput
}