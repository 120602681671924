import React from "react"
import { Outlet } from "react-router-dom"
import ThemeProvider from "../../context/ThemeProvider";

const Root = () => {
    return (
        <ThemeProvider>
            <React.Fragment>
                <Outlet />
            </React.Fragment>
        </ThemeProvider>
    );
}

export default Root;