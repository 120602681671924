import React from "react";
import { createRoot } from "react-dom/client";
// import {
//   createBrowserRouter,
//   Navigate,
//   RouterProvider,
//   // Route,
//   // Link,
// } from "react-router-dom";

import "react-loading-skeleton/dist/skeleton.css";

import App from "./app/App";


createRoot(document.getElementById("root")).render(
  <App />
);
