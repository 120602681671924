const FetchData = async (model, payload) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const defApiVer = process.env.REACT_APP_API_DEF_VER;
    const url = apiUrl + defApiVer + '/' + model;
    const token = localStorage.getItem('KTDTTOK') ?? null;
    let dataPayload = JSON.stringify(payload);

    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };
    if (payload instanceof FormData) {
        dataPayload = payload;
        headers = {};
    }
    if (token != null) {
        headers = { ...headers, Authorization: `Bearer ${token}` }
    }
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: dataPayload
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        }
        else {
            throw data;
        }
    }
    catch (error) {
        throw error;
    }
}

export default FetchData;