/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../context/ThemeContext";
import FetchData from "../Helper/HttpRequestHelper";
import cryptoJs from 'crypto-js';

const GuestGuard = ({ children }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('KTDTTOK');
    const searchParams = new URLSearchParams(window.location.search);
    const next = searchParams.get("continue");
    const urlError = searchParams.get("error");
    const tokenType = searchParams.get("typ");
    const { toggleSpinner } = useContext(ThemeContext);
    const secretKey = process.env.REACT_APP_SECRET;

    useEffect(() => {
        __checkUser();
    }, [token, navigate]);
    
    const encToken = (token) => {
        var chiperTxt = '';
        if(tokenType === 'b') {
            chiperTxt = btoa(token);
        }
        else {
            const plainTxt = JSON.stringify({ token: token });
            chiperTxt = cryptoJs.AES.encrypt(plainTxt, secretKey).toString();
        }
        return chiperTxt;
    }

    const __checkUser = async () => {
        if (urlError === 'auth-expired') {
            localStorage.removeItem('KTDTTOK');
            localStorage.removeItem('KTDTUSR');
        }
        if(!token) return;
        const login = await __checkIsLogedIn();
        if (!!next && token) {
            toggleSpinner(true);
            if (login) {
                // const plainTxt = JSON.stringify({token: token});
                // const chiperTxt = cryptoJs.AES.encrypt(plainTxt, secretKey).toString();
                var chiperTxt = encToken(token);
                window.location.replace(next + '?s=' + encodeURIComponent(chiperTxt));
                return;
            }
            else {
                localStorage.removeItem('KTDTTOK');
                localStorage.removeItem('KTDTUSR');
                toggleSpinner(false);
            }
            return;
        }
        if (token && login) {
            navigate(process.env.REACT_APP_LOGIN_REDIRECT);
        }
    }

    const __checkIsLogedIn = async () => {
        const res = await FetchData('member/me');
        return res.status_code === 200;
    }

    return children;
}

export default GuestGuard;
