import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import FetchData from "../../../Helper/HttpRequestHelper";
import { buildUrlAuth } from "../../../Helper/UrlHelper";
import { Link } from "react-router-dom";
// import { Spinner } from "react-bootstrap";

const Dashboard = () => {

    const [networks, setNetworks] = useState([]);
    const { user } = useContext(UserContext);
    // const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDataNetworks();
    }, []);

    const getDataNetworks = () => {
        // setLoading(true)
        FetchData('networks').then(res => {
            setNetworks(res.data);
        }, err => {
            console.log('terjadi kesalahan.', err);
        }).finally(() => {
            // setLoading(false);
        });
    }

    const setNetworkUrl = (network) => {
        const url = network.url;
        if (network.auth) {
            return buildUrlAuth(url);
        }
        return url;
    }

    return (
        <>
            <div className="d-flex">
                <h3 className='mb-1'>Hallo, {user.name}</h3>
            </div>
            <hr style={{ borderColor: '#dedede', borderWidth: '2px' }} />
            <div className="mt-4">
                <h5 style={{ color: '#404040' }}>Katadata Networks :</h5>
                <div className="row mt-3">
                    {
                        networks.map((network) => (
                            <div className="col-6 col-md-6 col-lg-4">
                                <Link to={setNetworkUrl(network)} className="d-block" target="_blank">
                                    <div className="card">
                                        <div className="card-body bg-light network text-center">
                                            <img src={network.image} alt="" className="w-75 network-img" />
                                            <p className="network-description">{network.description}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )

}

export default Dashboard;