/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FetchData from "../../../Helper/HttpRequestHelper";
import { toHumanDateTime } from "../../../Helper/StringHelper";
import Skeleton from "react-loading-skeleton";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SubscriptionPage = (props) => {
  const query = useQuery();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const [subType, setActiveSubType] = useState(query.get("subtype"));
  const [type, setActiveType] = useState(query.get("type"));

  useEffect(() => {
    setActiveSubType(query.get("subtype"));
  }, [type]);

  const menus = [
    {
      type: "subscription",
      text: "Bulanan / Tahunan",
    },
    {
      type: "retail",
      text: "Per Artikel (Retail)",
    },
  ];

  const subMenus = [
    {
      type: "active",
      text: "ACTIVE",
    },
    {
      type: "inactive",
      text: "INACTIVE",
    },
    {
      type: "expired",
      text: "EXPIRED",
    },
  ];

  return (
    <>
      <h2 className="mb-4">Subscription</h2>
      <nav className="nav nav-tabs mb-4">
        {menus.map((menu, index) => (
          <li className="nav-item">
            <Link
              to={`/m/subscription?type=${menu.type}&subtype=active`}
              onClick={() => setActiveType(menu.type)}
              className={`nav-link ${
                query.get("type") === menu.type ? "active" : ""
              }`}
            >
              {menu.text}
            </Link>
          </li>
        ))}
      </nav>
      <div className="mb-3">
        {subMenus.map((menu, index) => (
          <button
            className={`btn me-2 ${
              subType === menu.type ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => {
              setActiveSubType(menu.type);
              params.set("subtype", menu.type); // Update or set the query param
              navigate(`?${params.toString()}`);
            }}
          >
            {menu.text}
          </button>
        ))}
      </div>
      {query.get("type") === "subscription" ? (
        <SubscriptionView subType={subType} />
      ) : (
        <RetailView subType={subType} />
      )}
    </>
  );
};

const LoadingSkeleton = () => (
  <div className="card mb-4 card-transaction skeleton">
    <div className="card-body">
      <div
        className="card-title d-flex justify-content-between align-items-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <div className="invoice-label">
          <Skeleton width={150} />
        </div>
        <div>
          <Skeleton width={100} />
        </div>
      </div>
    </div>
    <div className="card-text">
      <dl className="d-flex">
        <dt className="col-4 col-md-3">
          <Skeleton width={150} />
        </dt>
        <dd>
          <Skeleton width={200} />
        </dd>
      </dl>
      <dl className="d-flex">
        <dt className="col-4 col-md-3">
          <Skeleton width={150} />
        </dt>
        <dd>
          {" "}
          <Skeleton width={400} />
        </dd>
      </dl>
      <dl className="d-flex">
        <dt className="col-4 col-md-3">
          <Skeleton width={150} />
        </dt>
        <dd>
          <Skeleton width={300} />
        </dd>
      </dl>
    </div>
    <div className="card-footer d-flex justify-content-end align-items-center gap-2">
      <Skeleton width={70} height={25} />
      <Skeleton width={70} height={25} />
    </div>
  </div>
);

const __fetchDataTransaction = async (type, subType = "all") => {
  const url =
    type === "subscription" ? "member/subscriptions" : "member/retail";
  const result = await FetchData(url, {
    status: subType,
  });
  return result;
};

const TransactionItem = ({ transaction }) => {
  const [loadingSingleReadHistory, setLoadingSingleReadHistory] =
    useState(false);
  const [loadingMultipleReadHistory, setLoadingMultipleReadHistory] =
    useState(false);
  const [totalBasicUsed, setTotalBasicUsed] = useState(0);
  const [readBasicHistories, setReadBasicHistories] = useState([]);
  const [totalMultipleUsed, setTotalMultipleUsed] = useState(0);
  const [readMultipleHistories, setReadMultipleHistories] = useState([]);
  const databoksUrl = process.env.REACT_APP_DATABOKS_URL;
  const basic_limit = process.env.REACT_APP_DEFAULT_BASIC_LIMIT;
  const multipe_limit = process.env.REACT_APP_DEFAULT_MULTIPLE_LIMIT;

  useEffect(() => {
    if (transaction?.product_subscription?.name === "Basic") {
      __getDataReadHistory();
    } else {
      __getDataMultipleReadHistory();
    }
  }, []);

  const __getDataMultipleReadHistory = async () => {
    setLoadingMultipleReadHistory(true);
    const res = await FetchData("article/premium/read-multiple-data-history");
    if (res.status_code === 200) {
      setTotalMultipleUsed(res.data.total);
      setReadMultipleHistories(res.data.data);
    }
    setLoadingMultipleReadHistory(false);
  };

  const __getDataReadHistory = async () => {
    setLoadingSingleReadHistory(true);
    const res = await FetchData("article/basic/read-history");
    if (res.status_code === 200) {
      setTotalBasicUsed(res.data.total);
      setReadBasicHistories(res.data.data);
    }
    setLoadingSingleReadHistory(false);
  };

  return (
    <div className="card mb-4 card-transaction">
      <div className="card-title d-flex justify-content-between">
        {transaction?.transaction?.description || "-"}
        {transaction.status === "ACTIVE" &&
          transaction.product_subscription_id <= 7 && (
            <Link
              to={`${databoksUrl}/pricing`}
              target="_blank"
              className="btn btn-sm btn-info ms-2 d-none d-md-block"
            >
              Upgrade Plan
            </Link>
          )}
      </div>
      <div className="card-body">
        <div className="card-text">
          {transaction?.article && (
            <dl className="d-flex">
              <dt className="col-4 col-md-3">Data</dt>
              <dd>
                {" "}
                <Link to={transaction?.article.url} target="_blank">
                  {transaction?.article?.nama || "-"}
                </Link>
              </dd>
            </dl>
          )}
          <dl className="d-flex">
            <dt className="col-4 col-md-3">Durasi</dt>
            <dd>
              {toHumanDateTime(transaction?.subscription_start, true)} -{" "}
              {toHumanDateTime(transaction?.subscription_end, true)}&nbsp;
            </dd>
          </dl>
          {transaction?.extend_subscription && (
            <dl className="d-flex">
              <dt className="col-4 col-md-3">Durasi Baru</dt>
              <dd>
                {" "}
                {toHumanDateTime(
                  transaction?.extend_subscription?.subscription_start,
                  true
                ) +
                  " - " +
                  toHumanDateTime(
                    transaction?.extend_subscription?.subscription_end,
                    true
                  )}
              </dd>
            </dl>
          )}
          {transaction?.transaction !== null && (
            <dl className="d-flex">
              <dt className="col-4 col-md-3">Invoice</dt>
              <dd>{transaction?.transaction?.invoice_number || "-"}</dd>
            </dl>
          )}
          <dl className="d-flex">
            <dt className="col-4 col-md-3">Status</dt>
            <dd>
              {" "}
              <span
                className={`px-3 py-2 ${
                  transaction.status === "EXPIRED" ||
                  transaction.status === "INACTIVE-EXPIRED" ||
                  transaction.status === "INACTIVE-UPGRADE"
                    ? "bg-danger"
                    : "bg-success"
                } rounded text-white small font-weight-bold`}
              >
                {transaction.status}
              </span>
            </dd>
          </dl>
          {transaction.status === "ACTIVE" &&
            transaction?.product_subscription &&
            !loadingSingleReadHistory && (
              <>
                <dl className="d-flex">
                  <dt className="col-4 col-md-3 d-none d-md-block">
                    Akses ke single data
                  </dt>
                  <dt className="col-4 col-md-3 d-block d-md-none">
                    Akses ke <br /> single data
                  </dt>
                  <dd>
                    {" "}
                    {transaction?.product_subscription?.name === "Professional"
                      ? "Unlimited"
                      : transaction?.product_subscription?.name === "Basic"
                      ? `${totalBasicUsed}/${basic_limit}`
                      : "-"}
                  </dd>
                </dl>
                {transaction?.product_subscription?.name === "Basic" && (
                  <dl className="">
                    <dt className="col-4 col-md-3"></dt>
                    <dd>
                      <ol className="p-3">
                        {readBasicHistories.map((read, index) => (
                          <li key={index} className="mb-1">
                            <Link to={read?.article?.url} target="_blank">
                              {read?.article?.nama}
                            </Link>
                          </li>
                        ))}
                      </ol>
                    </dd>
                  </dl>
                )}
              </>
            )}
          {transaction.status === "ACTIVE" &&
            transaction?.product_subscription?.name === "Professional" &&
            !loadingMultipleReadHistory && (
              <>
                <dl className="d-flex">
                  <dt className="col-4 col-md-3">Akses ke multiple data</dt>
                  <dd>
                    {totalMultipleUsed}/{multipe_limit} data
                  </dd>
                </dl>
                <dl className="d-flex description">
                  <dt className=""></dt>
                  <dd>
                    <ol className="p-3">
                      {readMultipleHistories.map((read, index) => (
                        <li key={index} className="mb-1">
                          <Link to={read?.article?.url} target="_blank">
                            {read?.article?.nama}
                          </Link>
                        </li>
                      ))}
                    </ol>
                  </dd>
                </dl>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

const SubscriptionView = (props) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();
  const { subType } = props;

  useEffect(() => {
    __getDataTransaction();
  }, []);

  useEffect(() => {
    __getDataTransaction(subType);
  }, [subType]);

  const __getDataTransaction = async (sunType = "all") => {
    setLoading(true);
    const res = await __fetchDataTransaction("subscription", subType);
    if (res.status_code === 200) {
      setTransactions(res.data.data);
    } else if (res.message === "Unauthenticated.") {
      navigate("/login?path=" + encodeURIComponent(window.location.pathname));
    } else {
      console.log("Error when fetch api get data transaction");
    }
    setLoading(false);
  };

  return (
    <>
      {loading === false && transactions.length === 0 ? (
        <div className="alert alert-info">Transaksi tidak ditemukan</div>
      ) : (
        <>
          {loading === true ? (
            <LoadingSkeleton />
          ) : (
            transactions.map((transaction, index) => (
              <TransactionItem key={index} transaction={transaction} />
            ))
          )}
        </>
      )}
    </>
  );
};

const RetailView = (props) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();
  const { subType } = props;

  useEffect(() => {
    __getDataTransaction();
  }, []);

  useEffect(() => {
    __getDataTransaction(subType);
  }, [subType]);

  const __getDataTransaction = async (subType = "all") => {
    setLoading(true);
    const res = await __fetchDataTransaction("retail", subType);
    if (res.status_code === 200) {
      setTransactions(res.data.data);
    } else if (res.message === "Unauthenticated.") {
      navigate("/login?path=" + encodeURIComponent(window.location.pathname));
    } else {
      console.log("Error when fetch api get data transaction");
    }
    setLoading(false);
  };

  return (
    <>
      {loading === false && transactions.length === 0 ? (
        <div className="alert alert-info">Transaksi tidak ditemukan</div>
      ) : (
        <>
          {loading === true ? (
            <LoadingSkeleton />
          ) : (
            transactions.map((transaction, index) => (
              <TransactionItem key={index} transaction={transaction} />
            ))
          )}
        </>
      )}
    </>
  );
};
export default SubscriptionPage;
