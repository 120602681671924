import cryptoJs from 'crypto-js';

const buildUrlAuth = (url) => {
    const secretKey = process.env.REACT_APP_SECRET;
    const token = localStorage.getItem('KTDTTOK');
    console.log(token);
    const plainTxt = JSON.stringify({token: token});
    const chiperTxt = cryptoJs.AES.encrypt(plainTxt, secretKey).toString();
    return url + '?s=' + encodeURIComponent(chiperTxt);
}

export {
    buildUrlAuth
}