import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import ThemeContext from "../../context/ThemeContext";

const ConfirmModal = () => {
    const { confirm, hideConfirm } = useContext(ThemeContext);

    // const __handleClose = () => hideConfirm;
    function __handleConfirm() {
        confirm.callback();
        hideConfirm();
    }

    return (
        <Modal
            show={confirm.show}
            onHide={hideConfirm}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="d-grid gap-3 text-center my-3">
                <h2 className="h4">{confirm.title}</h2>
                <div className="mb-2 text-grey">
                    {confirm?.message}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    className="fw-bold"
                    onClick={hideConfirm}>Batal</Button>
                <Button
                    variant="primary"
                    className="fw-bold"
                    onClick={__handleConfirm}>Lanjutkan</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmModal;