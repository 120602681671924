import moment from "moment";
import 'moment/locale/id'


var toRupiah = (number) => {
    // return `Rp. ${number.toFixed(2).toLocaleString('id-ID')}`
    return new Intl.NumberFormat(
        'id-ID',
        {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    ).format(number);
}

var toHumanDateTime = (dateStr, dateOnly = false) => {
    moment.locale('id');
    const date  = moment(dateStr);
    const format = dateOnly ? 'DD MMMM YYYY' : 'DD MMMM YYYY HH:mm';
    return date.format(format);
}

export {
    toRupiah,
    toHumanDateTime
}