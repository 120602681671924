/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ThemeContext from "../../../context/ThemeContext";
import FetchData from "../../../Helper/HttpRequestHelper";
import { Spinner } from "../../components/misc";
import showPasswordImg from '../../../../assets/img/eye.svg';
import hidePasswordImg from '../../../../assets/img/eye-slash.svg';

const ResetPasswordPage = () => {

    const [frmErrors, setFrmErrors] = useState(null);
    const [password, setPassword] = useState('');
    const [isFormReady, setIsFormReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const {setAlert} = useContext(ThemeContext);
    const { token } = useParams();
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email");
    const navigate = useNavigate();


    useEffect(() => {
        _checkToken();
    }, []);

    const _checkToken = () => {
        FetchData('reset-password/check-token-update-password', {
            email: email,
            token: token
        }).then(response => {
            if (response.success === false && response.status_code === 422) {
                navigate('/');
            }
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        if (password !== '') {
            setIsFormReady(true);
        }
        else {
            setIsFormReady(false);
        }
    }, [password]);

    const _onSubmitHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const payload = {
            token: token,
            email: email,
            password: password
        };
        FetchData('reset-password', payload).then(response => {
            if (response.status_code === 200) {
                setAlert({show: true, title: 'Berhasil Reset Password', message: 'Sekarang kamu bisa login ke akun menggunakan password baru 🙌', loginBtn: true });
            }
            else {
                setFrmErrors(response.errors);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    return (
        <React.Fragment>
            <h2 className="card-title text-center">Buat Password</h2>
            <p className="text-center">Silahkan masukan password baru kamu</p>
            <form onSubmit={_onSubmitHandler} className="d-grid gap-3 my-4">
                <div className="form-group">
                    <div className={`input-group ${!!frmErrors?.password ? 'has-validation' : ''}`}>
                        <input
                            placeholder="Password*"
                            type={!showPassword ? 'password' : 'text'}
                            name="password"
                            className={`form-control ${!!frmErrors?.password ? 'is-invalid' : ''}`}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }} />
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="toggle-password"
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}>
                                <img src={ showPassword ? showPasswordImg : hidePasswordImg } alt="" />
                        </button>
                        {frmErrors?.password &&
                            <div className="invalid-feedback">
                                {frmErrors?.password[0]}
                            </div>
                        }
                    </div>
                </div>
                <button 
                    type="submit" 
                    className={`btn ${!isFormReady ? 'btn-disabled' : 'btn-primary'}`} 
                    disabled={!isFormReady}>
                    {isLoading ?
                        <Spinner />
                        :
                        'Simpan'
                    }
                </button>
            </form>
        </React.Fragment>
    )
}

export default ResetPasswordPage;