import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FetchData from '../../../Helper/HttpRequestHelper';
import { generateToken } from "../../../Helper/MiscHelper";
import { validateEmail } from "../../../Helper/Validation";
import { BsInput } from "../../components/bootstrap";
import { Spinner } from "../../components/misc";

const ForgotPasswordPage = () => {

    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [frmError, setFrmError] = useState(null);
    const [notifError, setNotifError] = useState('');
    const [isFormReady, setIsFormReady] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (email !== '' && validateEmail(email)) {
            setIsFormReady(true);
        }
        else {
            setIsFormReady(false);
        }
    }, [email]);

    const _onSubmitHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        FetchData('reset-password/send-link', { email: email }).then(response => {
            if (response.success === true && response.status_code === 200) {
                const token = generateToken(10);
                localStorage.setItem('tempUR', JSON.stringify({ token: token, email: email }));
                navigate(`/forget-password/${token}`);
            }
            else if (response.success === false && response.status_code === 422) {
                setFrmError(response.errors);
            }
            else {
                setNotifError(response.message);
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <React.Fragment>
            <h2 className="card-title text-center">Reset Password</h2>
            <p className="text-center">Masukan email yang terdaftar dan kami akan mengirimkan email intruksi untuk mengatur ulang kata sandi kamu</p>
            {!!notifError &&
                <div className="alert alert-danger">
                    Mohon coba kembali setelah 1 menit.
                </div>
            }
            <form onSubmit={_onSubmitHandler} className="d-grid gap-3 my-4">
                <div className="form-group">
                    <BsInput
                        placeholder="Email : john@gmail.com"
                        type="text"
                        name="email"
                        className="form-control"
                        errors={frmError?.email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }} />
                </div>
                <button type="submit" className={`btn ${!isFormReady ? 'btn-disabled' : 'btn-primary'}`} disabled={!isFormReady}>
                    {isLoading ?
                        <Spinner />
                        :
                        'Kirim Link Reset Password'
                    }
                </button>
            </form>
        </React.Fragment>
    )
}

export default ForgotPasswordPage;