/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../../../context/ThemeContext";
import FetchData from "../../../Helper/HttpRequestHelper";

const LogoutPage = () => {

    const {toggleSpinner} = useContext(ThemeContext);
    const navigate = useNavigate();

    useEffect(() => {
        __doLogout();
    }, []);

    const __doLogout = () => {
        toggleSpinner(true);
        FetchData('logout').then(res => { }).catch(err => {
            console.log(err);
        }).finally(() => {
            localStorage.removeItem('KTDTTOK');
            localStorage.removeItem('KTDTUSR');
            setTimeout(() => {
                toggleSpinner(false);
                navigate('/login');
            }, 500);
        });
    }
    return (
        <>
            <p className="text-center">Please wait...</p>
        </>
    )
}

export default LogoutPage;