/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import envelopeImg from "../../../../assets/img/envelop.svg";
import { Spinner } from "../../components/misc";
import FetchData from "../../../Helper/HttpRequestHelper";

const timeOut = 60;

const SendResetLinkSuccessPage = () => {

    const { token } = useParams();
    const [email, setEmail] = useState('');
    const [isResendEmailReady, setIsResendEmailReady] = useState(false);
    const [resendEmailLoading, setResendEmailLoading] = useState(false);
    const [seconds, setSeconds] = useState(timeOut);
    const [notification, setNotification] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        initPage();
    }, []);

    const initPage = () => {
        const tempUrl = JSON.parse(localStorage.getItem('tempUR'));
        if(!tempUrl && tempUrl?.token !== token) {
            navigate('/');
        }
        setEmail(tempUrl?.email || null);
        setTimeout(() => {
            localStorage.removeItem('tempUR');
        }, 2000);
    }

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => { setSeconds(seconds - 1) }, 1000);
        }
        else {
            setIsResendEmailReady(true);
        }
    }, [seconds]);

    const _onResendEmail = () => {
        setResendEmailLoading(true);
        setNotification(null);
        FetchData('reset-password/send-link', { email: email }).then(response => {
            if (response.success === true && response.status_code === 200) {
                setNotification({
                    type: 'success',
                    text: 'Email berhasil dikirim.'
                });
            }
            else {
                setNotification({
                    type: 'danger',
                    text: 'Mohon coba beberapa menit lagi.'
                });
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setResendEmailLoading(false);
            setSeconds(timeOut);
            setIsResendEmailReady(false);
        });
    }

    return (
        <React.Fragment>
            <img src={envelopeImg} className="img-envelop d-block m-auto" alt="" />
            <p className="text-center mt-4 my-4 text-grey lh-base">
                Link Reset Password telah dikirim ke email <span className="text-dark">{email}</span>.<br />
                Silahkan ikuti intruksi untuk mereset password kamu.
            </p>
            { !!notification &&
                <div className={`alert alert-${notification?.type}`}>
                    { notification.text }
                </div>
            }
            <button
                className={`btn w-100 ${!isResendEmailReady ? 'btn-disabled' : 'btn-primary'}`}
                disabled={!isResendEmailReady}
                onClick={_onResendEmail}
            >
                {!isResendEmailReady ?
                    `Kirim ulang dalam ${seconds}`
                    :
                    resendEmailLoading ?
                        <Spinner />
                        :
                        `Kirim ulang`
                }
            </button>
            <div className="d-flex justify-content-center">
                <Link to="/" className="mt-2">Kembali ke halaman login</Link>
            </div>
        </React.Fragment>
    )
}

export default SendResetLinkSuccessPage;