import React, { lazy, Suspense } from "react";

import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

import LoginPage from "./view/pages/auth/LoginPage";
import RegisterPage from "./view/pages/auth/RegisterPage";
import Root from "./view/layouts/Root";
import AuthLayout from "./view/layouts/AuthLayout";
import ForgotPasswordPage from "./view/pages/auth/ForgetPasswordPage";
import VerifyEmailPage from "./view/pages/auth/VerifyEmailPage";
import CleanLayout from "./view/layouts/CleanLayout";
import SendResetLinkSuccessPage from "./view/pages/auth/SendResetLinkSuccessPage";
import ResetPasswordPage from "./view/pages/auth/ResetPasswordPage";
import SubmitVerifyEmail from "./view/pages/auth/SubmitVerifyEmail";
import UserContextProvider from "./context/UserContext";
import PanelGuard from "./guards/PanelGuard";
import GuestGuard from "./guards/GuestGuard";
import ManagePassword from "./view/pages/panel/ManagePassword";
import LogoutPage from "./view/pages/auth/LogoutPage";
import Dashboard from "./view/pages/panel/Dashboard";
import TransactionPage from "./view/pages/panel/TranasctionPage";
import SubscriptionPage from "./view/pages/panel/SubscriptionPage";

const PanelLayout = lazy(() => import('./view/layouts/PanelLayout'));
const ManageAccount = lazy(() => import('./view/pages/panel/ManageAccount'));

const Loader = () => {
  return <div>Loading</div>;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Suspense fallback={<Loader />}><Root /></Suspense>,
    children: [
      {
        element: <GuestGuard><AuthLayout /></GuestGuard>,
        children: [
          {
            path: "/",
            element: <Navigate to="/login" />
          },
          {
            path: "/login",
            element: <LoginPage />,
          },
          {
            path: "/register",
            element: <RegisterPage />
          },
          {
            path: "/forget-password",
            element: <ForgotPasswordPage />
          },
          {
            path: "/reset-password/:token",
            element: <ResetPasswordPage />
          },
        ]
      },
      {
        element: <CleanLayout />,
        children: [
          {
            path: "/verify-your-email/:id_enc",
            element: <VerifyEmailPage />
          },
          {
            path: "/forget-password/:token",
            element: <SendResetLinkSuccessPage />
          },
          {
            path: "/verif-email/:id/:token",
            element: <SubmitVerifyEmail />
          }
        ]
      },
      {
        element: <PanelGuard><PanelLayout /></PanelGuard>,
        children: [
          {
            path: "/m/dashboard",
            element: <Dashboard />
          },
          {
            path: "/m/manage-account",
            element: <ManageAccount />
          },
          {
            path: "/m/manage-password",
            element: <ManagePassword />
          },
          {
            path: "/m/transaction",
            element: <TransactionPage />
          },
          {
            path: "/m/subscription",
            element: <SubscriptionPage />
          },
          {
            path: '/logout',
            element: <LogoutPage />
          }
        ]
      }
    ]
  },
]);

const App = () => {
  return (
    <UserContextProvider>
      <RouterProvider router={router} />
    </UserContextProvider>
  )
}

export default App;