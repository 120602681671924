/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import iconGoogle from "../../../../assets/img/icon-google.png";
import FetchData from '../../../Helper/HttpRequestHelper';
import { Spinner } from '../../components/misc';
import { BsInput } from "../../components/bootstrap";
import cryptoJs from 'crypto-js';
import { Alert } from 'react-bootstrap';



function LoginPage() {

    const [userInfo, setUserInfo] = useState({ email: '', password: '' });
    const [isFormReady, setIsFormReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState({});
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const [isRedirect, setIsRedirect] = useState(false);
    const next = searchParams.get("continue");
    const errorMsg = searchParams.get("error");
    const redTo = searchParams.get("path");
    const tokenSet = searchParams.get("s");
    const tokenType = searchParams.get('typ');
    const privateLogin = searchParams.get('private');
    const secretKey = process.env.REACT_APP_SECRET;

    useEffect(() => {
        if (next) {
            setIsRedirect(true);
            sessionStorage.setItem('redirect', next);
        }
    }, [next]);

    useEffect(() => {
        if (tokenSet) {
            _setTokenFromQuery();
        }
    }, [tokenSet]);

    const _setTokenFromQuery = async () => {
        // check token isValid
        localStorage.setItem('KTDTTOK', tokenSet);
        const user = await FetchData('member/me');
        if (user?.data) {
            localStorage.setItem('KTDTUSR', JSON.stringify(user.data));
            const sessionRedirect = sessionStorage.getItem('redirect');
            if (!!sessionRedirect) {
                const chiperTxt = encToken(tokenSet);
                window.location.href = sessionRedirect + '?s=' + encodeURIComponent(chiperTxt);
            }
            else {
                window.location.href = process.env.REACT_APP_LOGIN_REDIRECT;
            }
        }
    }

    const _onInputChange = (event) => {
        let newUserInfo = { ...userInfo };
        newUserInfo[event.target.name] = event.target.value;
        setUserInfo({
            ...newUserInfo,
        });
    }

    useEffect(() => {
        if (userInfo.email !== '' && userInfo.password !== '') {
            setIsFormReady(true);
        }
        else {
            setIsFormReady(false);
        }
    }, [userInfo]);

    const _onSubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);
        setTimeout(() => {
            _atemptLogin();
        }, 10);
    }

    const encToken = (token) => {
        var chiperTxt = '';
        if (tokenType === 'b') {
            chiperTxt = btoa(token);
        }
        else {
            const plainTxt = JSON.stringify({ token: token });
            chiperTxt = cryptoJs.AES.encrypt(plainTxt, secretKey).toString();
        }
        return chiperTxt;
    }

    const _atemptLogin = () => {
        FetchData('login', userInfo).then(respose => {
            setIsLoading(false);
            if (respose.status_code !== 200) {
                setLoginError(respose);
            }
            else {
                const token = respose.data?.token;
                const user = respose.data?.user;
                localStorage.setItem('KTDTTOK', token);
                const shortUserData = {
                    name: user?.name,
                    image_src: user?.image_src
                }
                localStorage.setItem('KTDTUSR', JSON.stringify(shortUserData));
                const chiperTxt = encToken(token);
                if (isRedirect) {
                    window.location.replace(next + '?s=' + encodeURIComponent(chiperTxt));
                }
                else {
                    if (redTo) {
                        navigate(redTo);
                    }
                    else {
                        window.location.href = process.env.REACT_APP_LOGIN_REDIRECT;
                    }
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const loginGoogle = () => {
        window.location.href = process.env.REACT_APP_API_URL + 'auth/google?continue=' + next;
    }

    return (
        <React.Fragment>
            <h2 className="card-title text-center">Masuk</h2>
            <p className="card-text text-center">Login untuk mengakses data dan informasi terkini. Satu akun untuk semua platform.</p>
            {errorMsg === 'auth-expired' &&
                <Alert variant='danger'>
                    Sesi anda telah habis mohon login kembali.
                </Alert>
            }
            <form onSubmit={_onSubmitHandler} className="d-grid gap-3 my-4">
                <BsInput
                    placeholder="Email"
                    type="text"
                    name="email"
                    className="form-control"
                    errors={loginError?.errors?.email}
                    onChange={_onInputChange} />
                <BsInput
                    placeholder="Password"
                    type="password"
                    name="password"
                    className={`form-control`}
                    errors={loginError?.errors?.password}
                    onChange={_onInputChange} />
                <div className="text-end">
                    <Link to="/forget-password">Lupa Password?</Link>
                </div>
                <input type="submit" className='d-none' />
            </form>
            <div className="d-grid gap-3">
                <button type='submit' className={`btn ${!isFormReady ? 'btn-secondary' : 'btn-primary'}`} disabled={!isFormReady} onClick={_onSubmitHandler}>
                    {
                        !isLoading ?
                            'Masuk'
                            :
                            <Spinner />
                    }
                </button>
                {!privateLogin &&
                    <>
                        <div className="text-center text-muted">Atau Gunakan</div>
                        <button className="btn btn btn-outline-secondary muted" onClick={loginGoogle}>
                            <img src={iconGoogle} alt="" className="icon-google" />&nbsp;
                            Masuk dengan Google
                        </button>
                        <div className="text-center">
                            <span className="text-muted">Belum punya akun?</span> <Link to="/register">Daftar Sekarang!</Link>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
}

export default LoginPage;
