import React from "react";
import { Outlet } from "react-router-dom";
import "../../../assets/scss/auth.scss";
import LogoID from '../../../assets/img/logo-id.svg'


const AuthLayout = () => {
    return (
        <div className="main">
            <div className="container">
                <div className="d-flex justify-content-center align-items-center min-vh-100">
                    <div className="col-11 col-lg-4">
                        <div className="card">
                            <div className="card-header">
                                {/* <h1 className="text-white">Katadata ID</h1> */}
                                <img src={LogoID} className="logo-id" alt="" />
                            </div>
                            <div className="card-body">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayout;